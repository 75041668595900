import styled from 'styled-components';
import { LAYOUT } from '@/constants/styles';

export const StyledAccountContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 'var(--size-3)',
  paddingTop: 'var(--size-3)',

  '@media (min-width: 768px)': {
    gap: 'var(--size-6)',
    paddingTop: 'var(--size-6)',
  },
});

export const StyledSecondarySection = styled.div({
  margin: '0 auto',
  maxWidth: `${LAYOUT.MAX_LOYALTY_CONTAINER_WIDTH}px`,
  padding: `${LAYOUT.GUTTER * 2}px 0`,
  width: '100%',
});

export const StyledLoyaltyContainer = styled.div({
  maxWidth: `${LAYOUT.MAX_LOYALTY_CONTAINER_WIDTH}px`,
  margin: `${LAYOUT.LOYALTY_MENU_HEIGHT_DESKTOP}px auto 0`,

  '@media (max-width: 767px)': {
    marginTop: `${LAYOUT.LOYALTY_MENU_HEIGHT_MOBILE}px`,
  },
});

export const StyledFlexedHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
});

export const StyledTopLevelLoyaltyContainer = styled.div({
  marginTop: `${LAYOUT.LOYALTY_MENU_HEIGHT_DESKTOP}px`,

  '@media (max-width: 767px)': {
    marginTop: `${LAYOUT.LOYALTY_MENU_HEIGHT_MOBILE}px`,
  },
});

export const StyledForm = styled.form({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  justifyContent: 'center',
  width: '100%',
});

export const StyledInlineFormContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
});

interface StyledFormFieldProps {
  autoHeight?: boolean;
}

export const StyledFormField = styled.div<StyledFormFieldProps>(({ autoHeight, theme }) => ({
  flex: '1 1 33.333%',
  height: autoHeight ? 'auto' : `${theme.forms.field_height + LAYOUT.GUTTER}px`,
  overflow: 'hidden',
  position: 'relative',

  '&:not(:first-child)': {
    paddingLeft: `${LAYOUT.GUTTER}px`,

    '@media (max-width: 767px)': {
      paddingLeft: 0,
    },
  },

  '@media (max-width: 767px)': {
    flexBasis: '100%',
  },
}));

export const FormInput = styled.input({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  border: `1px solid #e0ded9`,
  height: '48px',
  marginBottom: '20px',
  padding: '0 12px',
  width: '100%',
  color: '#231f20',
  fontSize: '16px',
  fontFamily: 'Jost, Arial, Helvetica',
});

export const StyledCancelAndSubmitButtons = styled.div({
  display: 'flex',
  justifyContent: 'right',
  alignSelf: 'flex-end',

  '@media (max-width: 767px)': {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    margin: 0,

    button: {
      width: '100%',
    },

    '> div:first-child': {
      margin: `${LAYOUT.GUTTER}px 0 0 0`,
    },
  },
});

export const StyledInlineCancelAndSubmitButtons = styled(StyledCancelAndSubmitButtons)({
  position: 'relative',
  top: '-20px',

  '@media (max-width: 767px)': {
    top: 0,
  },
});

export const StyledContactInfoContainer = styled.div({
  padding: `${LAYOUT.GUTTER}px`,

  p: {
    margin: `${LAYOUT.GUTTER / 4}px 0`,
    fontSize: '16px',
  },
});

export const StyledContactInfoContent = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '@media (max-width: 767px)': {
    display: 'block',
  },
});

export const StyledCancelEdit = styled.div({
  display: 'flex',
  marginRight: `${LAYOUT.GUTTER}px`,
  alignItems: 'center',
});

interface StyledLinkButtonProps {
  $bold?: boolean;
}

export const StyledLinkButton = styled.button<StyledLinkButtonProps>(({ $bold, theme }) => ({
  fontFamily: $bold ? theme.primary_font.bold.font_family : theme.primary_font.regular.font_family,
  fontSize: '16px',
  color: theme.global.primary_active_color,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: 0,

  '&:hover': {
    opacity: 0.8,
  },
}));
