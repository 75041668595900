import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

class MagicBox {
  /**
   * Flatten an object and get paths to all its properties
   *
   * @param object
   * @param path
   * @param paths
   * @returns {*}
   * @TODO improve typing of `object` and `paths`.
   */
  static flatten(object: Record<string, any>, path: string, paths: Record<string, any>) {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (typeof object[key] === 'object') {
          MagicBox.flatten(object[key], `${path}.${key}`, paths);
        } else if (path !== '') {
          paths[key] = `${path.slice(1)}.${key}`;
        } else {
          paths[key] = key;
        }
      }
    }

    return paths;
  }

  /**
   * Build arbitrarily nested filters
   *
   * @param filters
   * @param key
   * @returns {Array}
   * @TODO improve typing of `filters`.
   */
  static buildFilters(filters: any, key: string) {
    // Flatten the filters object to retrieve an object of {column_name: 'path.in.original.object'
    const flattened = MagicBox.flatten(filters, '', {});
    const filterStrings = [];

    for (const column in flattened) {
      if (flattened.hasOwnProperty(column)) {
        // Remove column name from path, replace ORs and ANDs
        const orString = flattened[column]
          .toLowerCase()
          .replace(column, '')
          .replace(/or\./g, '[or]')
          .replace(/and\./g, '[and]');

        // Find the filter's value by the retrieved path
        const filter =
          orString.length === 0
            ? filters[column]
            : get(filters, flattened[column].replace(column, ''))[column];

        // Build the query param part
        filterStrings.push(`${key + orString}[${column}]=${filter}`);
      }
    }

    return filterStrings;
  }

  /**
   * Build a query string (of modifiers) such as per_page, filter, etc
   *
   * Modifiers can look like:
   * const modifiers = {
   * 		filters: {
   * 		    foo: '=bar',
   * 		    or : {
   * 		        baz            : 'bat',
   * 		        'relation.attr': '[1,2,3]'
   * 		    }
   * 		},
   * 		sort   : {
   * 		    'foo': 'asc'
   * 		},
   * 		include: [
   * 		    'foo.bar',
   * 		    'baz.bat',
   * 		]
   * 	};
   *
   * @param {object} modifiers
   * @returns {string}
   * @TODO improve the typing of `modifiers`.
   */
  static buildUrlModifiers(modifiers: Record<string, any>) {
    let parts: string[] = [];
    for (const key in modifiers) {
      // Handle filters and sorts as php assoc. arrays ['key' => 'value']
      if (key === 'filters') {
        parts = parts.concat(MagicBox.buildFilters(modifiers[key], key));
      } else if (key === 'sort' || key === 'aggregate') {
        const part = modifiers[key];

        for (const column in part) {
          if (part.hasOwnProperty(column)) {
            parts.push(`${key}[${column}]=${part[column]}`);
          }
        }
      } else if (key === 'include') {
        // @ts-expect-error
        modifiers[key].forEach((relation) => {
          parts.push(`include[]=${relation}`);
        });
      } else {
        // Handle others as objects to be serialized
        parts.push(MagicBox.serialize(modifiers[key]));
      }
    }

    if (parts.length > 0) {
      return parts.join('&');
    }

    return '';
  }

  /**
   * Serialize an object into a string of query parameters. This does not return the beginning ?.
   *
   * @param object
   * @returns {string}
   */
  static serialize(object: Record<string, string | number | boolean>) {
    const parts = [];
    for (const param in object) {
      if (object.hasOwnProperty(param)) {
        parts.push(`${encodeURIComponent(param)}=${encodeURIComponent(object[param])}`);
      }
    }

    return parts.join('&');
  }

  /**
   * Break apart a query string into an object
   *
   * @param queryString
   * @returns {{}}
   */
  static deserialize(queryString: string) {
    const pairs = queryString.split('&');

    const params: Record<string, string> = {};
    pairs.forEach((pair) => {
      const p = pair.split('=');
      params[p[0]] = decodeURIComponent(pair[1] || '');
    });

    return params;
  }
}

/**
 * A MagicContainer clones itself to prevent weird javascript stuff
 */
class MagicContainer {
  clone() {
    return cloneDeep(this);
  }
}

/**
 * MagicBoxParams is a container for filters, includes, sorts, pagination which get
 * passed to a MagicBox API.
 */
class MagicBoxParams extends MagicContainer {
  filters;
  sort;
  include;
  page;
  per_page;
  without_pagination = false;

  /**
   *
   * @param {object} filters
   * @param {object} sort
   * @param {array} include
   * @param {number} page
   * @param {number} perPage
   */
  constructor(
    filters?: object,
    sort?: object,
    include?: string[],
    page?: number,
    perPage?: number,
  ) {
    super();
    this.filters = filters || {};
    this.sort = sort || {};
    this.include = include || [];
    this.page = page || 1;
    this.per_page = perPage || 20;
  }

  /**
   * Get the modifier object
   *
   * @returns {object}
   */
  modifiers() {
    return Object.assign(
      {},
      {
        filters: this.filters,
        sort: this.sort,
        include: this.include,
      },
    );
  }

  /**
   * Get the query for the modifier params
   *
   * @returns {string}
   */
  modifierQuery() {
    return MagicBox.buildUrlModifiers(this.modifiers());
  }

  /**
   * Get a pagination object
   *
   * @returns {object}
   */
  pagination() {
    return Object.assign(
      {},
      {
        page: this.page,
        per_page: this.per_page,
      },
    );
  }

  /**
   * Get a pagination query
   *
   * @returns {string}
   */
  paginationQuery() {
    return MagicBox.serialize(this.pagination());
  }

  /**
   * Turn a MagicBoxParams container into a query string
   *
   * @returns {string}
   */
  toQuery() {
    const modQ = this.modifierQuery();

    if (!modQ) {
      return this.without_pagination ? '' : this.paginationQuery();
    }

    return this.without_pagination ? `${modQ}` : `${modQ}&${this.paginationQuery()}`;
  }

  /**
   * Set pagination options
   *
   * @param {number} page
   * @param {number} per_page
   * @returns {MagicBoxParams}
   */
  setPagination(page: number, perPage: number) {
    this.page = page;
    this.per_page = perPage;

    return this;
  }

  /**
   * Set the filters object
   *
   * @param {object} filters
   * @returns {MagicBoxParams}
   */
  setFilters(filters: unknown) {
    /** @TODO improve the typing of `filters`. */
    // @ts-expect-error
    this.filters = filters;

    return this;
  }

  /**
   * Add a single filter
   *
   * @param {string} key
   * @param {string} value
   * @returns {MagicBoxParams}
   */
  addFilter(key: string, value: string) {
    /** @TODO improve the typing of `filters`. */
    // @ts-expect-error
    this.filters[key] = value;

    return this;
  }

  /**
   * Set the sort object
   *
   * @param {object} sort
   * @returns {MagicBoxParams}
   */
  setSorts(sort: unknown) {
    /** @TODO improve the typing of `sort`. */
    // @ts-expect-error
    this.sort = sort;

    return this;
  }

  /**
   * Add a single sort
   *
   * @param {string} key
   * @param {string} dir
   * @returns {MagicBoxParams}
   */
  addSort(key: string, dir: string) {
    /** @TODO improve the typing of `sort`. */
    // @ts-expect-error
    this.sort[key] = dir;

    return this;
  }

  /**
   * Set the include object
   *
   * @param {string[]} include
   * @returns {MagicBoxParams}
   */
  setIncludes(include: string[]) {
    this.include = include;

    return this;
  }

  /**
   * Add a single include
   *
   * @param {string} include
   */
  addInclude(include: string) {
    this.include.push(include);
  }

  /**
   * Ignore pagination
   */
  withoutPagination(): this {
    this.without_pagination = true;
    return this;
  }
}

export { MagicBoxParams };
