import { type Location } from '@koala/sdk';
import compact from 'lodash/compact';
import { useState, useEffect } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import SecondaryLocationCtaModal from './modal';
import { StyledSecondaryButton } from './styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { K_ANALYTICS_EVENTS } from '@/constants/events';
import { SECONDARY_CTA_MAPPINGS } from '@/constants/locations';
import { type RootState } from '@/types/app';
import { type ISecondaryCtaMapping } from '@/types/locations';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';
import { fireKAnalyticsEvent } from '@/utils/koalaAnalytics';
import { reconcileCustomAttributeWithGlobalValue, safelyGetString } from '@/utils/stringHelpers';

interface Props extends ReduxProps {
  location: Location;
  favorites?: boolean;
}
//FunctionComponent<never> | ComponentType<never>
// JSX.Element | (JSX.Element | null)[] | FunctionComponent<never> | ComponentType<never>
const SecondaryCtaManager = (props: Props) => {
  const [secondaryCtaArray, setSecondaryCtaArray] = useState<(JSX.Element | null)[]>([]);
  const [showOverflowModal, setShowOverflowModal] = useState<boolean>(false);

  useEffect(() => {
    // This assumes we've already fetched the strings via the server
    setSecondaryCtaArray(deriveSecondaryCtaArray());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSecondaryCtaArray(deriveSecondaryCtaArray());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location]);

  const deriveSecondaryCtaArray = () =>
    SECONDARY_CTA_MAPPINGS.map((ctaMap: ISecondaryCtaMapping, index: number) => {
      const { strings, location, favorites } = props;
      const globalValue = safelyGetString(
        strings,
        `location_ctas.${ctaMap.accessor}_value`,
        location,
      );
      const globalLabel = safelyGetString(
        strings,
        `location_ctas.${ctaMap.accessor}_label`,
        location,
      );

      const { label, value } = reconcileCustomAttributeWithGlobalValue(
        // @ts-expect-error @TODO improve  of custom attributes
        location.attributes,
        ctaMap.position,
        globalValue,
        globalLabel,
      );

      // Determine whether we're accessing location card from homepage or Favorites
      const koalaAnalyticsLabel = favorites
        ? K_ANALYTICS_EVENTS.LOCATION_FAVORITE_CTA
        : K_ANALYTICS_EVENTS.LOCATION_CTA;

      // Ensure provider enabled and we have both values before displaying cta
      if (!location.provider_enabled || !value || !label) {
        return null;
      }

      return (
        <StyledSecondaryButton
          key={index}
          onClick={() => {
            // GA event
            fireGaEvent(gaCats.order, gaActions.orderDelivery, {
              label: location.label,
            });

            // Requested per: [KOALA-5979 Pass LOCATION_CTA event and name to Google Analytics](https://koalalabs.atlassian.net/browse/KOALA-5979)
            fireGaEvent(gaCats.order, gaActions.clickLocationCardTertiaryCta, {
              label: label,
            });

            // KA event
            fireKAnalyticsEvent(koalaAnalyticsLabel, {
              name: label,
              details: value,
            });
          }}
          href={value}
          target="_blank"
        >
          {label}
        </StyledSecondaryButton>
      );
    });

  // const { secondaryCtaArray, showOverflowModal } = this.state;
  const lengthOfValidCtas = compact(secondaryCtaArray).length;

  // If 2 or more CTAs exist, trigger the consoliation modal
  if (lengthOfValidCtas > 2) {
    return (
      <div>
        <StyledSecondaryButton as="button" onClick={() => setShowOverflowModal(true)}>
          <StringAccessor accessor="location_ctas.overflow_modal_trigger_label" />
        </StyledSecondaryButton>
        <SecondaryLocationCtaModal
          ctas={secondaryCtaArray}
          visible={showOverflowModal}
          onToggle={() => setShowOverflowModal(false)}
        />
      </div>
    );
  }

  // Otherwise, display the CTAs normally
  return <div>{secondaryCtaArray}</div>;
};

const mapStateToProps = (state: RootState) => ({
  strings: state.app.cmsConfig.strings,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(SecondaryCtaManager);
