import styled from 'styled-components';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { LAYOUT } from '@/constants/styles';

export const StyledButton = styled(StyledPrimaryButton)<{
  $shouldHide: boolean;
}>`
  margin: 0 0 ${LAYOUT.GUTTER / 2}px;
  padding: 0 ${LAYOUT.GUTTER / 2}px;
  width: 100%;

  ${({ $shouldHide }) =>
    $shouldHide &&
    `
    display: none;
  `}

  @media (max-width: 767px) {
    margin: ${LAYOUT.GUTTER / 2}px auto 0;
    width: 80%;
  }
`;
