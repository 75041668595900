import { type Location } from '@koala/sdk';
import { memo } from 'react';
import { useTheme } from 'styled-components';

interface Props {
  location: Location;
}
const _LocationConveyance = ({ location }: Props) => {
  const theme = useTheme();
  // Ensure support
  if (!theme.location_card.show_conveyance_types) {
    return null;
  }

  // Assemble conveyance types object
  const conveyanceObject = {
    Curbside: location.supports_curbside,
    Delivery: location.supports_delivery,
    'Drive Thru': location.supports_drive_thru,
    Pickup: location.supports_pick_up,
    'Dine In':
      location.supports_to_stay ||
      location.supports_dine_in ||
      location.supports_dine_in_table_number,
    'Drive In': location.supports_drive_in,
  };

  // Cast supported conveyance types to array
  const conveyanceArray = Object.keys(conveyanceObject).filter(
    // @ts-expect-error `key` can't index `conveyanceObject`.
    (key) => conveyanceObject[key],
  );

  return (
    <p>
      {conveyanceArray.map(
        (key: string, index: number) => `${key}${conveyanceArray.length - 1 !== index ? ', ' : ''}`,
      )}
    </p>
  );
};

export const LocationConveyance = memo(_LocationConveyance);
