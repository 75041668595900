import styled from 'styled-components';
import { LAYOUT } from '@/constants/styles';

/**
 * This file contains a bunch of catch-all style components for form elements.
 * As individual layouts are refactored, we should stop using these components
 * because they could be implemented out more easily using reusable layout
 * components.
 */

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledPaddingContainer = styled.div`
  padding: ${LAYOUT.GUTTER}px;
`;

export const StyledExplanationText = styled.p`
  font-size: 16px;
`;
