import { useSelector } from '@/redux';

interface Props {
  crossStreetDetail: string | null;
}

export const CrossStreet = ({ crossStreetDetail }: Props) => {
  const crossStreetState = useSelector(
    (state) => state.app.cmsConfig.webConfig.locations.cross_street,
  );
  const crossStreetLabel = useSelector(
    (state) => state.app.cmsConfig.strings.locations.cross_street_label,
  );

  if (!crossStreetState || crossStreetDetail == null) {
    return null;
  }

  return (
    <>
      {crossStreetLabel && `${crossStreetLabel} `}
      {crossStreetDetail}
      <br />
    </>
  );
};
